import React from "react";
import { Layout } from "~/components";

import { CelebrationsOtherServices } from "~/views";

const HeliCelebrationsOtherServices: React.FunctionComponent = () => (
  <Layout title="Heli-Celebrations & Other Services">
    <CelebrationsOtherServices />
  </Layout>
);

export default HeliCelebrationsOtherServices;
